import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withMediaHosts from '../../hoc/with-media-hosts';

import resolveUserImage from '../../services/resolve-user-image';
import FluidAvatarImage from '../fluid-avatar-image';
import styles from './avatar-image.scss';

const IMAGE_LARGE = 'large';
const IMAGE_EXTRA_LARGE = 'extraLarge';
const IMAGE_PROMOTE = 'promote';
const IMAGE_WIDGET = 'widget';
const IMAGE_COMMENT = 'comment';
const IMAGE_COMMENT_SEARCH = 'commentSearch';

const getImageSize = styleVariable => parseInt(styleVariable, 10) * 2;

const IMAGE_SIZE_MAP = {
  [IMAGE_LARGE]: getImageSize(styles.IMAGE_SIZE_LARGE),
  [IMAGE_EXTRA_LARGE]: getImageSize(styles.IMAGE_SIZE_EXTRA_LARGE),
  [IMAGE_PROMOTE]: getImageSize(styles.IMAGE_SIZE_PROMOTE),
  [IMAGE_WIDGET]: getImageSize(styles.IMAGE_SIZE_WIDGET),
  [IMAGE_COMMENT]: getImageSize(styles.IMAGE_SIZE_COMMENT),
  [IMAGE_COMMENT_SEARCH]: getImageSize(styles.IMAGE_SIZE_COMMENT_SEARCH),
};
const IMAGE_SIZE_DEFAULT = getImageSize(styles.IMAGE_SIZE_DEFAULT);

const AvatarImage = ({ component: Component, type, image, className, ariaLabel, imageHost, ...componentProps }) => {
  const imageSize = get(IMAGE_SIZE_MAP, type, IMAGE_SIZE_DEFAULT);
  return (
    <Component
      className={classNames(styles.image, className, styles[type], 'avatar-image')}
      {...omit(componentProps, 'tReady', 'videoHost')}
    >
      <FluidAvatarImage
        ariaLabel={ariaLabel}
        image={resolveUserImage(image, { width: imageSize, height: imageSize, imageHost })}
      />
    </Component>
  );
};

AvatarImage.LARGE = IMAGE_LARGE;
AvatarImage.EXTRA_LARGE = IMAGE_EXTRA_LARGE;
AvatarImage.PROMOTE = IMAGE_PROMOTE;
AvatarImage.WIDGET = IMAGE_WIDGET;
AvatarImage.COMMENT = IMAGE_COMMENT;
AvatarImage.COMMENT_SEARCH = IMAGE_COMMENT_SEARCH;

AvatarImage.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
  imageHost: PropTypes.string,
  videoHost: PropTypes.string,
};

AvatarImage.defaultProps = {
  component: 'span',
};

export default withMediaHosts(AvatarImage);
