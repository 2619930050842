export const getWrappersHorizontalMargin = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  layoutMargins,
  createdWithResponsiveEditor,
}) => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return 'auto';
  }

  return createdWithResponsiveEditor ? 0 : layoutMargins;
};

export const getContainerWidth = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  width,
  layoutMargins,
  borderWidth,
  createdWithResponsiveEditor,
  layoutPostSize,
  layoutSlider,
}) => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return layoutPostSize;
  }

  if (layoutSlider) {
    return width - layoutMargins * 2 - borderWidth * 2;
  }

  if (createdWithResponsiveEditor || layoutMargins < 1) {
    return width;
  }

  return width - layoutMargins * 2;
};
